<template>
  <div>
    <div class="lang" v-if="lang=='de'" @click="lang=''">
      <img src="en.gif"><span>ENGLISH</span>
    </div>
    <div class="lang" v-else @click="lang='de'">
      <img src="aut.gif"><span>DEUTSCH</span>
    </div>
  <h2>
    <span v-if="lang=='de'">Gästeregistrierung</span>
    <span v-else>Guest Registration</span>
  </h2>
  
  <div v-if="guest.id==0">
    <table class="guest">
      <tr>
        <td class="label">
          Name *
        </td>
      
        <td>
          <input type="text" class="text guest" id="name" placeholder= "Name *" :class="{'err': !guest.name}" v-model.trim= "guest.name" />
        </td>
      </tr>
      <tr>
        <td class="label">
          <span v-if="lang=='de'">Vorname</span>
          <span v-else>Forename</span>
          *
        </td>
        <td>
          <input type="text" class="text guest" placeholder= "Vorname *" :class="{'err': !guest.vname}" v-model.trim= "guest.vname" />
        </td>
      </tr>

      <tr>
        <td class="label">
          <span v-if="lang=='de'">Telefon</span>
          <span v-else>Phone</span>
          *
        </td>
        <td>
          <input type="text" class="text guest" placeholder= "Telefon *" :class="{'err': !validTel()}" v-model.trim= "guest.tel" />
        </td>
      </tr>

      <tr>
        <td class="label">
          Email
        </td>
        <td>
          <input type="text" class="text guest" placeholder= "Email" v-model.trim= "guest.email" />
        </td>
      </tr>

      <tr v-if="autoOut.options.length">
        <td class="label h100">
          <p v-if="lang=='de'">Abmelden<br>nach</p>
          <p v-else>check out<br>after</p>
        </td>
        <td class="auto-out">
          <!--
          <input type="number" min=0 max=3 value=2 v.model.number=autoOut>
          -->
          <select v-model= "guest.out">
            <option v-for="(o,key) in autoOut.options" :key=key :value=o >{{o==0 ? '---' : o}}</option>
      
          </select>  
          &nbsp;
          <span v-if="lang=='de'">Stunden</span>
          <span v-else>hours</span>
        </td>
      </tr>


      <tr>
        <td colspan=2 class="groupBtn">
          <button @click="addGroup(-1)">
            <span v-if="lang=='de'">Angehörige</span>
            <span v-else>Family members</span>
            ...
          </button>
        </td>
      </tr>
      <tr>
        <td colspan=2>
          <table class="group">
            <tr v-for="(g, key) in guest.group" :key=key>
              <td class="inputs" :class="{'border-top': key > 0}">
                <input type="text" class="text name" placeholder= "Name" v-model.trim= "g.name" />
                <input type="text" class="text" placeholder= "Vorname*" :class="{'err': !g.vname}" v-model.trim= "g.vname" />
              </td>
              <td class="h-right buttons" :class="{'border-top': key > 0}">
                <button @click="addGroup(key)"><i class="fa fa-plus" /></button>
                <button @click="delGroup(key)" class="del"><i class="fa fa-minus" /></button>
              </td>
            </tr>

          </table>
        </td>
      </tr>

    
    </table>
    <input type="checkbox" v-model= "guest.dsgvo"/>
    <span @click= "guest.dsgvo = !guest.dsgvo">&nbsp;
      <span v-if="lang=='de'">
        Ich nehme zur Kenntnis, dass auf Grund der Gesetzeslage eingegebene Daten und meine Aufenthaltsdauer 28 Tage gespeichert werden.
      </span>
      <span v-else>
        I acknowledge that due to the legal situation, data entered and the time of my stay will be stored for 28 days.
      </span>
    </span>
    <br>
    <a :href="dataDir + 'dsgvo.pdf'" target="_blank">Details
      <span v-if="lang=='de'">der Datenerhebung</span>
    </a>
  </div>
  <div class="success" v-else-if="guest.id > 0">
    <h2>{{guest.vname}} {{guest.name}}</h2>
    <div class="groupList" v-if="guest.group.length">
      <p>
        <span v-if="lang=='de'">Angehörige</span>
        <span v-else>Family members</span>
      </p>
    
      <p v-for="(g,key) in guest.group" :key=key>
        {{g.name}} {{g.vname}}
      </p>
    </div>
    <span v-if="lang=='de'">angemeldet seit</span>
    <span v-else>registered since</span>
    <h3>{{guest.since}}</h3> 
  </div>

  <button :disabled="invalidInputs()" :class="[invalidInputs() ? 'disabled' : guest.id ? 'out' : 'danger']" @click="logIn">
    <span v-if="guest.id">
      <span v-if="lang=='de'">Abmelden</span>
      <span v-else>Log off</span>  
    </span>
    <span v-else>
      <span v-if="lang=='de'">Anmelden</span>
      <span v-else>Log in</span>
    </span>
  </button>
  <p class="timeAutoOut" v-if="((guest.out >0) & (guest.id >0))">
    <span v-if="lang=='de'">Automatische Abmeldung um </span>
    <span v-else>Automatic logout at </span>
    <b>{{fmtOut(guest.since)}}</b>
  </p>
  
  <br>
  </div>
</template>

<script>
var axios = require("axios").default;

export default {
  name: 'PanelClient',
  props: ['clientId', 'dataDir', 'autoOut'],
  data() {
    return {
      guest: {name: "", vname: "", tel: "", email: "", ort:"", id: -1, dsgvo: false, since: "", out: 0, group: []},
      lang: "de",
      test: 0,
    }
  },
  methods: {
    logIn() {
      var guest = this.guest, s ='c=' + this.clientId

      if (guest.id) {
        s += "&log=out&id=" + guest.id
      } else {
        guest.group = guest.group.filter(z => z.vname != "")
        s += '&log=in&data=' + encodeURIComponent(JSON.stringify(guest))
      }
      //console.log(guest)
      axios({
        method: 'post',
        url: 'api.php',
        data: s,
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        //console.log(response.data)
        /*
        if (response.data.timeout) {
          alert("Kein Aufenthalt zwischen 22:00 und 5:00")
          guest.id = 0
          return
        }
        */
        if (guest.id) {
          guest.id = 0
        } else {
          guest.id = response.data.id
          guest.since = response.data.since
          if (response.data.str) localStorage.setItem("guestLogin", response.data.str)
        }

      })
    },
    fmtOut(ATime) {
      const s= ATime + '',
        h= (Number(s.substr(0,2)) + this.guest.out) % 24
      return (h < 10 ? '0' :'') + h + s.substr(2)
    },

    invalidInputs() {
      return this.guest.name == "" || this.guest.vname == "" || !this.validTel() || !this.guest.dsgvo
    },

    validTel() {
      const regex = /^[+ 0]\d+\s?([- ]?\d[- ]?){8,}$/g
      return regex.test(this.guest.tel)
    },

    addGroup(nr) {
      const g = {name: this.guest.name, vname: ""}
      if (nr == -1) {
        if (this.guest.group.length==0) this.guest.group.push(g)
      } else {
        this.guest.group.splice(nr+1, 0, g)
      }
      
    },
    delGroup(nr) {
      this.guest.group.splice(nr, 1)
    }
    
  },
  created() {
    const s= localStorage.getItem('guestLogin')
    if (s) {
      var th = this
      
      axios({
        method: 'post',
        url: 'api.php',
        data: 'checkGuest=1&c=' + this.clientId + '&data=' + encodeURIComponent(s),
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        th.guest= response.data.guest
        th.guest.out = th.autoOut.options.length ? th.autoOut.def : 0
        //console.log(th.guest)
      })
    } else {
      this.guest.id =0
      this.guest.out = th.autoOut.options.length ? th.autoOut.def : 0
    }
  },
}
</script>

<style scoped lang="sass">
h2
  margin-top: 5px
  margin-bottom: 2px

h3
  font-size: 64pt

p
  padding: 0
  margin: 0

div
  text-align: center
  margin: 0 auto

div.success       
  margin-top: 2em

table
  margin: 0 auto

  td
    padding: .5em 5px
    
  td.label
    white-space: nowrap
    text-align: right
    padding-right: .2em

input.text
  width: 18em
  
button
  padding: 0.2em 2em
  margin-top: 1.5em
 
button.out
  background: green


button.disabled 
  background: silver

.transparent
  color: white

ul 
  list-style-type: none
  padding: 0

li 
  display: inline-block
  margin: 0 10px

a 
  color: #42b983

.lang
  margin-top: 9px
  cursor: pointer
  img
    padding-right: 7px
    vertical-align: text-bottom
  span
    border-bottom: 1px dotted silver

.auto-out
  text-align: left
  
  input 
    width: 4em
  select
    width: 4em
    border: 1px solid silver

.timeAutoOut 
  margin: 0.5em auto
  font-size: 12pt

.groupList
  margin-bottom: 1em

.groupBtn button
  margin: 0
  background: gray

.group

  td
    padding: 2px 5px
    white-space: nowrap
    vertical-align: middle

  td.buttons
    padding: 0

  input
    width: 8em
    margin-right: 6px
    padding: 0.2em

  button
    padding: 3px 6px
    margin: 0
    background: #42b983f5
  button.del
    margin-left: 6px

@media only screen and (max-width: 466px) 
  table.guest
    width: 100%

    input.text.guest
      width: calc(100% - 17px)

  table.group
    td
      padding: 0
    td.inputs
      white-space: normal
      padding: 5px 0

      input.text
        width: 90% !important

      input.name
        margin-bottom: 3px

    td.border-top
      border-top: 1px dotted black

</style>
