<template>
<div>
  <div class="nav" v-if="show">
    <div class="btn" @click="showMenu=!showMenu" v-on-clickaway="away">
      ___<br>
      ___<br>
      ___<br>
    </div>
    <ul v-if="showMenu">
 <!--     
      <li :class="{active: show==1}" @click= "show=1">
        Personal
      </li>
-->
      <li :class="{active: show==5}" @click= "menuClick(5)">
        Manuelle Anmeldung
      </li>

      <li :class="{active: show==2}" @click= "menuClick(2)">
        Anwesenheit  
      </li>
      <li :class="{active: show==6}" @click= "menuClick(6)">
        K1 - Kontaktverfolgung
      </li>
      
      <li @click="report">
        28 Tage - Kontaktnachweis
      </li>

      <li class="logout" @click="logout">
        Logout
      </li>
    </ul>
  </div>

  <PanelLogin 
    v-if = "show==0"
    :clientId = clientId
    @set-show = "setShow"
  />

  <PanelManual
    v-if = "show==5"
    :clientId= clientId
    :autoOut = autoOut
  />
  <PanelK1
    v-if = "show==6"
    :clientId = clientId
    :dataDir = datadir
  />

  <PanelStat 
    v-if = "show==2"
    :clientId= clientId
    :conf = conf
    @save-conf = saveConf
  />
<!--
  <PanelPost
    v-if = "show==4"
  />

  <PanelStamm 
    v-if = "show==3"
  />
-->
</div>
</template>

<script>
import PanelLogin from './PanelLogin.vue'
import PanelManual from './PanelManual.vue'
import PanelK1 from './PanelK1.vue'
import PanelStat from './PanelStat.vue'
//import PanelStamm from './PanelStamm.vue'
//import PanelPost from './PanelPost.vue'


import { directive as onClickaway } from 'vue-clickaway';
var axios = require("axios").default

import { bus } from '../main'

export default {
  name: 'PanelAdmin',
  components: {
    PanelLogin, PanelManual, PanelStat, PanelK1, //PanelPost, PanelStamm
  },
  props: ['clientId', 'autoOut'],
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      show: 0, // 0: Login, 1:Personal, 2:Stat, 3: Stamm, 4:Nachmeldung, 5:Manual, 6: K1
      datadir: "",
      showMenu: false,
      conf : {"autoOut": ""}
    }
  },
  methods: {
    setShow() {
      this.show = 2
    },
    logout() {
      localStorage.removeItem('adminLogin')
      this.show = 0
    },
    report() {
      this.showMenu = false
      window.open(this.datadir + "/download.php?c=" + this.clientId, "_blank")
    },
    menuClick(nr) {
      bus.$emit('bye')
      this.showMenu = false
      this.show = nr 
    },
    away: function() {
      this.showMenu = false
    },
    saveConf(AConf) {
      this.conf = AConf
      alert(AConf)
    }
  },
  mounted() {
    this.datadir = process.env.NODE_ENV === "development" ? "http://localhost:8081/data" : "/data"
    const s= localStorage.getItem('adminLogin')
    if (s) {
      const th = this
      
      axios({
        method: 'post',
        url: 'api.php',
        data: 'checkAdmin=1&data=' + encodeURIComponent(s) + '&c=' + this.clientId,
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        //console.log(response.data)
        th.show = response.data.ok ? 5 : 0
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.nav
  text-align: left
  padding: 1px 0 0px 0
  position: absolute
  top: 7px
  left: 8px

  .btn
    width: 40px
    height: 33px
    padding-top: 4px
    font-weight: bold
    background: rgba(255,255,255, .95)
    border: 1px gray solid
    border-radius: 4px
    box-shadow: 3px 3px rgba(200,200,200, .7)
    text-align: center
    line-height: 5px
    cursor: pointer

  ul
    padding: 0
    margin: 3px 0
    border: 1px solid gray
    border-top: none
    box-shadow: 2px 2px rgba(200, 200, 200, .5)
    background: white
    font-size: 12pt

  li
    cursor: pointer
    list-style-type: none
    
    margin: 0
    padding: 6px 4em 6px 1em
    border-top: silver 1px solid
  li.logout
    border-top: 2px solid black

  li.active
    font-weight: bold
    letter-spacing: 0.5px

</style>
