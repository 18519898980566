<template>
  <div>
  <h2>Administrator - Login</h2>
  
  <input class="text loginText" paceholder="Benutzer" v-model.trim="user.name" />
  <input class="text loginText" paceholder="Passwort" v-model.trim="user.pass" />
  <button @click="login">Login</button>
  
</div>
</template>

<script>
var axios = require("axios").default

export default {
  name: 'PanelLogin',
  props: [
    "set-show", 'clientId', 
  ],
  data() {
    return {
      user: {name: "", pass: "", stay: true},
    }
  },
  methods: {
    login() {
      var th = this
      axios({
        method: 'post',
        url: 'api.php',
        data: 'adminLogin=1&c=' + this.clientId + '&data=' + encodeURIComponent(JSON.stringify(this.user)),
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        //console.log(response.data)
        if (response.data.ok) {
          if (response.data.str) localStorage.setItem("adminLogin", response.data.str)
          th.$emit('set-show')
        }
      })  
    }
    
  },
  created() {
    const s= localStorage.getItem('adminLogin')
    if (s) {
      const th = this
      
      axios({
        method: 'post',
        url: 'api.php',
        data: 'checkAdmin=1&data=' + encodeURIComponent(s) +'&c=' + this.clientId,
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        //console.log(response.data)
        if (response.data.user) th.user = response.data.user

      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
  input.loginText
    display: block
    margin: 7px auto
    width: 14em !important

  button
    background: green
    padding: 2px 2em
    margin: 1em auto 3em auto
</style>
