<template>
  <div>
    <h2>
      <span>K1 - Kontaktverfolgung</span>
    </h2>
    <button class="green top-button" v-if="activeRow > -1" @click="report">Exportieren</button>
    <table>
      
      <tr class="data" v-for="(p,key) in persList" :key=key @click="activeRow=key">
        <td :class="{'sel': activeRow == key, 'around': p.k}">
          {{p.day}}
        </td>
        <td :class="{'sel': activeRow == key, 'around': p.k}">
          {{p.von}}
        </td>
        <td :class="{'sel': activeRow == key, 'around': p.k}">
          <span v-if="p.otherDay" class="other-day">{{p.otherDay}} </span>{{p.bis}}
        </td>
        <td :class="{'sel': activeRow == key, 'around': p.k}">
          {{p.name}}
        </td>  
        <td :class="{'sel': activeRow == key, 'around': p.k}">
          {{p.vname}}
        </td> 
        <td :class="{'sel': activeRow == key, 'around': p.k}">
          {{p.tel}}
        </td> 
        <!--
        <td :class="{'sel': activeRow == key, 'around': p.k}">
          {{p.email}}
        </td>
        -->
        <td class="btn">
          <button class="green" v-if="activeRow==key" @click="report">Exportieren</button>
        </td>   
        
      </tr>
    </table>
    
  </div>
</template>

<script>
var axios = require("axios").default
import { bus } from '../main'

export default {
  name: 'PanelK1',

  props: ['dataDir', 'clientId'],

  data() {
    return {
      persList: [],
      activeRow: -1,
      cAround: 0,
     
    }
  },
  methods: {
    reload() {
      const th = this
      axios({
        method: 'post',
        url: 'api.php',
        data: "cmd=getAll&c=" + this.clientId,
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        //console.log(response.data)

        if (response.data.pers) {
          //
          th.persList= response.data.pers
          th.activeRow = response.data.pers.length ? 0: -1
        }
          
      })
    },

    report() {
      const c= this.persList.length
      let res=[], x
      for (let i=0; i<c; i++) {
        x= this.persList[i]
        if (x.k) res.push([
          x.login, 
          x.otherDay ? x.otherDay + " " + x.bis : x.bis, 
          x.name, 
          x.vname, 
          x.tel + " ", 
          i == this.activeRow ? "<b>"+x.email+"</b>" : x.email,
        ])
      }
      const p = this.persList[this.activeRow]
      window.open(this.dataDir + "/download.php?c=" + this.clientId + "&name=" + encodeURIComponent(p.name + " " + p.vname) + "&data=" + encodeURIComponent(JSON.stringify(res)), "_blank")
    }

  },

  watch: {
    activeRow(neu) {
      const lim = [
        this.persList[neu].login, 
        this.persList[neu].logout ? this.persList[neu].logout : "ZZZZZZ"
      ]
      this.cAround = 0
      for (let x of this.persList) {
        const von= x.login, bis = x.logout ? x.logout : "ZZZZZZ"
        x.k = bis >= lim[0] && von <= lim[1]
        if (x.k) this.cAround++
      }
    }
  },

  created() {
    bus.$on('bye', () => {
      this.reload()  
    })

    this.reload()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
table
  border-collapse: collapse
  margin: .5em auto

td
  text-align: left
  border: 1px silver solid
  white-space: nowrap
  background: white
  padding: 2px 4px
  cursor: pointer
  font-size: 10pt


td.around
  background: rgba(255, 0, 0, .2)
td.sel
  background: red
  color: white

td.btn
  border: none

.name
  width: 9em !important
.tel
  width: 11em !important
.email
  width: 9em !important
  margin-left: 38px

.other-day
  font-weight: bold
  font-size: 9pt

.top-button
  margin: 4px auto
  padding: 5px 12px

</style>
