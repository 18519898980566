<template>
  <div>
    <h2>
      <span>Gemeldete Gäste</span>
    </h2>
    
    <div v-if="stat.length">
      <table>
        <!--
        <tr class="sort">
          <td title="Sortieren" :class="{'active': sorted==1}" @click="sorted=1">&blacktriangledown;</td>
          <td></td>
          <td title="Sortieren" :class="{'active': sorted==0}" @click="sorted=0">&blacktriangledown;</td>
          <td class="btn"></td>
        </tr>
        -->
        <tr v-for="(k, key) in stat" :key=key>
          <td :class="{'selected': activeRow==key}" @click="activeRow=key">
            {{k.name}} <span v-if="k.stammId">(A)</span><span v-if="k.loginByStaff">*</span>
          </td>
          <td :class="{'selected': activeRow==key}" @click="activeRow=key">
            {{k.vname}}
          </td>
          <td class="since" :class="{'selected': activeRow==key}" @click="activeRow=key">
            <span class="not-today" v-if="k.notToday">{{k.notToday}}</span>{{k.since}}
          </td>
          <td class="btn">
            <button class="danger" @click="dussa(k.id, k.name + ' ' + k.vname)">Abmelden</button>
          </td>
          
        </tr>
      </table>

      <button class="logoutAll danger" @click="dussa()">Sperrstunde!</button>
      <br>
    </div>
    
    <p class="emptySpace" v-else>

    </p>
  </div>
</template>

<script>
var axios = require("axios").default
import { bus } from '../main'

export default {
  name: 'PanelStat',
  props: ['clientId', 'conf',
    'save-conf',
  ],
  data() {
    return {
      stat: [],
      sorted: 0, // 0: since, 1:name
      activeRow: -1, 
      oldConf: {},
      c: 0,
      arr: [0,5,6,2,1]
    }
  },
  methods: {
    dussa(id, n) {
      let s= 'c=' + this.clientId
      if (id) {
        if (!confirm("Gast \n" + n + "\n\nabmelden?")) return
        s += "&id=" + id
      } else if (!confirm("Alle Gäste abmelden?")) return
      
      const th = this
      axios({
        method: 'post',
        url: 'api.php',
        data: s + "&gastRaus=1",
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        if (response.data.errMsg) alert(response.data.errMsg)
        if (id) {
          th.stat = th.stat.filter(z => z.id != id)
        } else {
          th.stat= []
        }
        //th.stat = response.data.stat
      })
  
    },
    
    reload() {
      const th = this
      axios({
        method: 'post',
        url: 'api.php',
        data: "cmd=getStat&c=" + this.clientId,
        responseType: 'json',
        timeout: 200   
      }).then (response => {
        if (response.data.errMsg) alert(response.data.errMsg)
        if (response.data.stat) {
          th.stat = response.data.stat
        }
      })
    },
/*
    sortieren(iOrder) {
      if (iOrder == 0) {
        this.stat.sort(function(a, b){
          return a.since > b.since
        })
      } else {
        this.stat.sort(function(a, b){
          const aa= a.name + ":" + a.vname + ":" + a.since
          const bb= b.name + ":" + b.vname + ":" + b.since
          return aa > bb
        })
      }
      this.stat= []
      this.sorted = iOrder
      this.c++
    }
  */
  
  },

  /*
  watch: {
    sorted(newSorted) {
      if (newSorted == 0) {
        this.stat= this.stat.sort(function(a, b){
          return a.since > b.since
        })
      } else {
        this.stat= this.stat.sort(function(a, b){
          const aa= a.name + ":" + a.vname + ":" + a.since
          const bb= b.name + ":" + b.vname + ":" + b.since
          alert(aa > bb)
          return aa > bb
        })
      }
      this.c++
      //this.$forceUpdate()
    }
  },
  */
  
  created() {
    this.reload()
    this.oldConf = this.conf

    bus.$on('bye', () => {
      this.reload()  
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
  table
    margin: auto
    border-collapse: collapse

  td
    text-align: left
    border: 1px solid silver
    padding: 2px 1em 2px 4px
  
  tr.sort
    cursor: pointer
  
  td.since
    text-align: center
    padding: 2px 6px
  td.btn
    border: none

  span.not-today
    color: #dc3545
    font-weight: bold
    font-size: 9pt
    padding: 1px
    background: white

  button.success
    background: green
    margin-right: 3px

  .logoutAll
    margin: 1em 0 1em 0
    padding: .5em 1em

  .autoLogOut
    margin-top: 2px
    
    input
      width: 4em
    
    button
      margin-left: 2px

  
</style>
