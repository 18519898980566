<template>
  <div>
    <h2>
      <span>Manuelle Anmeldung</span>
    </h2>
    <table>
      <tr class="inputs">
        <td>
          <input placeholder="Name" class="name" type="text" v-model.trim="pers.name">  
        </td>  
        <td>
          <input placeholder="Vorname" class="vname" type="text" v-model.trim="pers.vname"> 
        </td>
        <td>
          <input placeholder="Telefon" class="tel" type="text" v-model.trim="pers.tel"> 
        </td>
        <td>
          <input placeholder="Email" class="email" type="text" v-model.trim="pers.email"> 
        </td>

        <td>
          <button :disabled="!validData(pers)" :class="{'green': validData(pers)}" @click="login(pers)">Anmelden</button>
        </td>
      </tr>

      <tr class="data" v-for="(p,key) in filteredList" :key=key>
        <td :class="{'selected': activeId==p.id}"  :id="'ed' + p.id" @click="detectClick(p.id)">
          <input type="text" placeholder="Name" class="name" v-if="editedId==p.id" @keyup="dirtyPers=p" v-model.trim="p.name" />
          <span v-else>
            {{p.name}} <span v-if="p.stammId">(A)</span><span v-if="p.loginByStaff">*</span>

          </span>
        </td>  
        <td :class="{'selected': activeId==p.id}" @click="detectClick(p.id)">
          <input type="text" placeholder="Vorname" class="vname" v-if="editedId==p.id" @keyup="dirtyPers=p" v-model.trim="p.vname" />
          <span v-else>
            {{p.vname}}
          </span>
        </td> 
        <td :class="{'selected': activeId==p.id}" @click="detectClick(p.id)">
          <input type="text" placeholder="Telefon" class="tel" v-if="editedId==p.id" @keyup="dirtyPers=p" v-model.trim="p.tel" />
          <span v-else>
            {{p.tel}}
          </span>
        </td> 
        <td :class="{'selected': activeId==p.id}" @click="detectClick(p.id)">
          <input type="text" placeholder="Email" class="email" v-if="editedId==p.id" @keyup="dirtyPers=p" v-model.trim="p.email" />
          <span v-else>
            {{p.email}}
          </span>
        </td>   
        
        <td class="btns">
          <button class="faButton" v-if="editedId!=p.id" @click="edit(p.id)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
          <button class="faButton save" :disabled="!validData(p)" v-if="editedId==p.id" @click="save(p,true)"><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
          <button class="faButton del" v-if="editedId==p.id" @click="del(p.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>
          
          &nbsp;
          <button :disabled="!validData(p)" :class="{'green':validData(p)}" @click="login(p)" v-if="p.logout && editedId!=p.id" >Anmelden</button>
          <button class="red" v-if="!p.logout && editedId!=p.id" @click="logout(p)" >Abmelden</button>
        </td>
        
      </tr>

    </table>
    
  </div>
</template>

<script>
var axios = require("axios").default
import { bus } from '../main'

export default {
  name: 'PanelManual',
  props: ['clientId', 'autoOut'],
  data() {
    return {
      persList: [],
      pers: {name: "", vname: "", tel: "", email: "", login: "", logout: "", id: 0, stammId: 0},
      editedId: 0,
      activeId: 0,
      dirtyPers: {},
      numClicks: 0,
      scrollToActiveId: false,
    }
  },
  methods: {
    detectClick: function(id) {
      this.numClicks++;
      if (this.numClicks === 1) {   
        var self = this;
        setTimeout(function() {
          switch(self.numClicks) {    
            case 1:
               // single
              if (self.editedId != id) {
                self.activeId = id
                self.editedId = 0
              }
              break;
            default:
              self.edit(id)
              // dbl
          }
          self.numClicks = 0;         
        }, 200);                     
      } 
    }, 

    validData(p) {
      return p.name && p.vname && this.validTel(p.tel)
    },
    validTel(nr) {
      const regex = /^[+ 0]\d+\s?([- ]?\d[- ]?){8,}$/g
      return regex.test(nr)
    },
    edit(id) {
      if (this.dirtyPers.name) this.save(this.dirtyPers, false)
      const filtered= this.pers.name !=  ""
      this.pers.name = ""
      this.editedId = id
      this.activeId = 0
      if (filtered) {
        this.$nextTick(() => document.getElementById("ed"+id).scrollIntoView())
        //console.log("scroll")
      }
      
    },
    save(p, btn) {
      const th = this
      axios({
        method: 'post',
        url: 'api.php',
        data: "cmd=saveVisit&data=" + encodeURIComponent(JSON.stringify(p)) + "&c=" + this.clientId,
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        if (response.data.errMsg) alert(response.data.errMsg)
        
        th.dirtyPers = {}
        if (btn) {
          th.editedId = 0
          th.activeId = p.id 
        }
        
        
      })
    },

    del(id) {
      if (!confirm('Löschen?')) return
      const th = this
      axios({
        method: 'post',
        url: 'api.php',
        data: "cmd=delVisit&id=" + id + "&c=" + this.clientId,
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        //console.log(response.data)
        if (response.data.ok) th.persList = th.persList.filter(z => z.id != id)
      })
    },

    reload() {
      const th = this
      axios({
        method: 'post',
        url: 'api.php',
        data: "cmd=getPers&c=" + this.clientId,
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        //console.log(response.data)
        if (response.data.pers) {
          th.persList= response.data.pers
          if (th.scrollToActiveId) {
            th.scrollToActiveId = false
            th.$nextTick(() => document.getElementById("app").scrollIntoView())
          }
        }
        
      })
    },

    login(p) {
      const th = this
         
      if (this.autoOut.options.length) {
        let out = prompt("Automatische Abmeldung nach ... Stunden", this.autoOut.def)
        p.out = Math.min(Number(out), 9)
      }
      axios({
        method: 'post',
        url: 'api.php',
        data: "log=in&admin=1&data=" + encodeURIComponent(JSON.stringify(p)) + "&c=" + this.clientId,
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        if (response.data.errMsg) {
          alert(response.data.errMsg)
        } else if (response.data.ok) {
          th.pers = {name: "", vname: "", tel:"", email:"", id: response.data.id}
          th.activeId = response.data.id
          th.scrollToActiveId = true
          th.reload()  
        }
      })
      .catch(error => {
        alert(error)
      })
      
    },
    logout(p) {
      const th = this
      if (!confirm(p.name + " " +p.vname +"\nabmelden?")) return
      axios({
        method: 'post',
        url: 'api.php',
        data: "log=out&id=" + p.id + "&c=" + this.clientId,
        responseType: 'json',
        //timeout: 200   
      }).then (response => {
        //console.log(response.data, p.id)
        if (response.data.ok) {
          th.activeId = p.id
          th.reload()
        }
      })  
    },

  },
  computed: {
    // a computed getter
    filteredList: function () {
      var th= this
      //const s = "LANG" //this.pers.name.toUpperCase, c= s.length
      return this.persList.filter(
        function(z) {
          var a= th.pers.name, c= a.length
          
          return z.name.substr(0,c).toLowerCase() == a.toLowerCase()
        }
      )  
    },
  },
  created() {
    bus.$on('bye', () => {
      this.reload()  
    })

    this.reload()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
h2 
  margin: 0.5em auto
table
  border-collapse: collapse
  margin: auto auto

td
  text-align: left
  border: 1px silver solid
  white-space: nowrap
  background: #eee
  padding: 1px 2px

input, td
  font-size: 10pt

.name
  width: 8em !important
.vname
  width: 5em !important
.tel
  width: 9em !important
.email
  width: 9em !important

tr.inputs
  td
    border: none
    background: white
    padding-bottom: 4px
    padding-left: 0

  input
    width: 6em
    border: 1px solid gray

  
tr.data
  input
    width: 6em

  .btns
    button
      margin-right: 3px

  button.save
    background: white
  button.save:disabled
    background: silver

  button.del 
    margin-left: 30px
    background: white

</style>
