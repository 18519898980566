<template>
  <div id="app">
    <div v-if="dataDir">
      <img :src="dataDir + 'logo.png'" border=0 @click="refresh">
      <PanelClient v-if="show==0" 
        :clientId= clientId
        :dataDir = dataDir
        :autoOut = autoOut
      />

      <PanelAdmin v-else 
        :clientId= clientId
        :autoOut = autoOut
      />
    
      <footer>
        &copy; <a href="https://www.raumplan.org" target="_blank">raumplan.org</a>, Peter Langgartner, Salzburg<br>
      </footer>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import VueRouter from "vue-router";

import { bus } from './main'

Vue.use(VueRouter);
var router = new VueRouter({
  mode: 'history',
  routes: []
});

var axios = require("axios").default;
axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "http://localhost:8081/data" : "/data"
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded"

import PanelClient from './components/PanelClient.vue'
import PanelAdmin from './components/PanelAdmin.vue'

export default {
  name: "App",
  router,
  components: {
    PanelClient, PanelAdmin
  },
  data() {
    return {
      show: -1, // 0: clients, 1: admin
      dataDir: "",
      clientId: 0,
      autoOut: {},
    }
  },
  methods: {
    refresh() {
      bus.$emit('bye')
    }
  },
  created: function() {
    const 
      th = this,
      params = this.$route.query,
      dir = process.env.NODE_ENV === "development" ? "http://localhost:8081/data/" : "/data/"
    
    //if (process.env.NODE_ENV === "development") console.log=function(){}
    
    this.clientId = params.c
    this.show =  typeof(params.admin) === "undefined" ? 0 : 1
    /*
    if (this.clientId == 10) {
      location.href="/404.php"
      return false;
    }
    */
    axios({
      method: 'post',
      url: 'api.php',
      data: 'getName=1&c=' + this.clientId,
      responseType: 'json',
      //timeout: 200   
    }).then (response => {
      //console.log(response.data)
      if (response.data.name) {
        th.dataDir = dir + response.data.name + "/"
        th.autoOut = response.data.autoOut
        document.title = response.data.haus + " - Gästeregistrierung"
      }
    })
  },
}

</script>

<style lang="sass">
#app 
  font-size: 10pt
  font-smooth: antialiased
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
  margin: 1em auto
  max-width: 500px

#app, input, button, select
  font-family: "Open Sans", Verdana, sans-serif

h1, h2, h3, h4
  font-weight: 500
  margin: 0
  padding: 0

h2 
  font-size: 14pt


input.text
  font-size: 12pt
  outline: none
  padding: 0.2em 0.5em
  border: 1px solid silver
  border-radius: 0.2em
  
select
  width: 100%
input.text
  width: 25em

input.text.err
  border-color: red


button
  font-size: 12pt
  color: white
  outline: none
  background: silver
  border: 1px solid gray
  border-radius: 4px

button.danger, button.red
  background: #dc3545
  border-color: #333

button.green
  background: green
  border-color: black

button.faButton
  color: black !important

select
  font-size: 12pt
  
h3
  font-size: 10pt

.invis
  display: none

footer
  padding: 2em 0 1em 0

  a, a:hover
    color: #2c3e50
    text-decoration: none
    border-bottom: 1px dotted gray

.h-left
  text-align: left
.h-center
  text-align: center
.h-right
  text-align: right

.h100 
  line-height: 100%
  
td.selected 
  background: #717968
  color: white

@media only screen and (max-width: 466px) 
  #app
    margin: 0.5em

</style>
